import { colors } from '../../../theme/colors'

export const blogContent = `
  color: ${colors.indigo};
  max-width: 830px;
  margin-left: auto; 
  margin-right: auto;

  & a {
    color: ${colors.indigo}
  }
`
